/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
body {
  .code-text {
    padding: 20px;
    //font-family: 'Monaco,Menlo,Consolas,"Courier New",monospace';
    font-family: monospace;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: #f5f5f5;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 12px;
    display: block;
  }

  .selectDisabled {
    cursor: not-allowed;
  }

  .text-right {
    text-align: right;
  }

  .tableRecebimento {
    div[role="gridcell"] div:first-child {
      white-space: normal !important;

      div[role="columnheader"] div:first-of-type {
        white-space: normal !important;
        overflow: hidden;
        text-overflow: initial;
        //word-spacing: 100vw;
      }
    }
  }
}

.dropzone {
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px dashed #7367f0;
  border-radius: 0.357rem;
}

.basic.tree {
  list-style: none;
  margin: 0;
  padding: 20px;
}

.basic .tree-node,
.basic .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.basic .tree-branch-wrapper,
.basic .tree-node__leaf {
  outline: none;
}

.basic .tree-node--focused {
  outline-color: rgb(77, 144, 254);
  outline-style: auto;
  outline-width: 2px;
  display: block;
}

.basic .tree-node__branch {
  display: block;
}

.basic .tree-node {
  cursor: pointer;
}

.directory {
  //background: #242322;
  font-family: monospace;
  font-size: 20px;
  color: #A17C52;
  user-select: none;
  padding: 20px;
  border-radius: 0.4em;
}

.directory .tree,
.directory .tree-node,
.directory .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.directory .tree-branch-wrapper,
.directory .tree-node__leaf {
  outline: none;
  outline: none;
}

.directory .tree-node {
  cursor: pointer;
}

.directory .tree-node:hover {
  background: rgba(255, 255, 255, 0.1);
}

.directory .tree .tree-node--focused {
  background: rgba(255, 255, 255, 0.2);
}

.directory .tree .tree-node--selected {
  background: rgba(48, 107, 176);
  color: white;
  transition: color 0.2s ease 0.1s, background-color 0.2s ease 0.1s,
    border-color 0.3s ease 0.2s;
}

.directory .tree-node__branch {
  display: block;
}

.directory .icon {
  vertical-align: middle;
  padding-right: 5px;
}

.MuiTreeItem-label {
  font-family: inherit !important;
  font-size: 24px !important;
}

.div-disabled {
  pointer-events: none;
  opacity: 0.5;
}


.table-overflow div[role="gridcell"] div:first-child {
  //      white-space: inherit !important;
  overflow: auto;
  text-overflow: unset;
}

[dir=ltr] .vertical-layout.vertical-menu-modern.no-menu .app-content,
[dir=ltr] .vertical-layout.vertical-menu-modern.no-menu .footer {
  margin-left: 0 !important;
}

[dir=ltr] .vertical-layout.vertical-menu-modern.no-menu .navbar.fixed-top,
[dir=ltr] .vertical-layout.vertical-menu-modern.no-menu .navbar.floating-nav {
  left: 0 !important;
  width: 100% !important;
  margin-top: 0;
}

.ecommerce-application {
  padding-top: 30px !important;
}

.ecommerce-carousel {
  height: 350px;
  overflow: hidden;
  margin-bottom: 40px;
}

.auth-wrapper.auth-cover .auth-inner {
  height: initial !important;
  justify-content: center;
}

// .img-fluid {
//     max-height: 500px;

// }

.fill {
  max-height: 500px !important;
  //max-width: 550px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 75%;
  display: block;
}

.smallimg {
  max-height: 100px !important;
}

.gallery-thumbs {
  border-radius: 0.358rem;
  margin-top: 10px;
}

// .swiper-slide img {
//     margin: 0 auto !important; /* this will align the image to center. */
//     width: auto !important; /* for those images which don't have a width specified, but has a max-height will be auto adjusted */
//   }

// .img-fluid {
//     max-height: 500px;
//     width: auto;
// }

.btn-categories {

  padding: 10px 30px 10px 20px;
  border: 1px solid #6e6b7b;
  font-weight: 600;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  text-transform: unset;
  text-transform: capitalize;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  padding: 0;
  position: relative;
  display: inline-flex;
  font-size: 14px;
  align-items: center;
  margin-right: 25px;

  svg {
    margin-right: 14px;

    path {
      fill: #6e6b7b;
    }
  }

  svg,
  img {
    max-width: 100%;
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    top: 15px;
    right: 10px;
    background: url('https://cdn.shopify.com/s/files/1/0717/5867/6254/t/2/assets/menu-dropdown.png');
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -ms-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    transition: all ease-in-out 0.5s;
  }
}

.mega-menu {
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 20px 0;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  background-color: #fff;
  transform-origin: top;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
  opacity: 0;
  visibility: hidden;
  min-width: 220px;
  z-index: 2;
  padding: 20px;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    li {
      margin-bottom: 10px;
      margin: 0;
      list-style: none;

      ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin-bottom: 10px;
          color: #23422C;
        }
      }
    }
  }
}

.btn-categories-wrapper:hover {
  a::after {
    transform: rotate(-180deg);
  }

  .btn-categories {
    background: #A17C52;
    color: #fff;
    border-color: #A17C52;
  }

  svg {
    path {
      fill: #fff;
    }
  }

  .mega-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.navbar-container {
  box-shadow: 0px 4px 55px rgba(24, 58, 64, 0.12);
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: #23422C;
}

.react-slideshow-container svg {
  fill: #23422C;
}

.swal2-html-container {
  overflow: unset !important;
}

.swal2-actions {
  z-index: 0 !important;
}

@media print {

  html,
  body {
    height: 90%;
    -webkit-print-color-adjust: exact;
  }

  body {
    margin: 15pt;
  }

  @media print {
    @page {
      size: landscape;
    }
  }

  #relatorio-div {

    font-size: 12px;

    table {
      page-break-inside: auto
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto
    }

    thead {
      display: table-header-group
    }

    tfoot {
      display: table-footer-group
    }

    table th,
    table td {
      padding: 0;
      margin: 0;
    }
  }
}

#table-parametros,
#table-alimentos {
  thead th {
    padding: 5px;
    text-align: center;
    font-size: 12px;

    &:first-child {
      width: 240px;
    }

    &:nth-child(2) {
      width: 115px;
      max-width: 115px;
    }

    &:nth-child(3) {
      width: 97px;
      max-width: 97px;
    }

    &:nth-child(4) {
      width: 140px;
      max-width: 140px;
    }
  }

  td {
    padding: 10px;
  }

  .form-check {
    justify-content: center;
    display: flex;
  }
}

.brand-logo img {
  width: 200px;
  height: 75px;
  max-width: 200px !important;
  max-height: 75px;
}

.navbar-brand {
  margin-top: 0 !important;
}

@media (max-width: 648px) {
  input.form-control {
    padding-left: 0.27rem;
    padding-right: 0.27rem;
    font-size: 0.8rem;
  }
}